const MenuItems = [
    {
        title: "Overview",
        url: "/",
        cName: 'nav-links'
    },
    {
        title: "Docs",
        url: "/Docs",
        cName: 'nav-links'
    },
    {
        title: "",
        url: "/Dapp",

    }
]

export { MenuItems }