import React, { Component } from 'react'
import './Docs.css'


export default class Docs extends Component {
    render() {
        return (
            <div className='docs-container'>
                <div className='docs-layout'>
                    <div className='docs-title'>Random text</div>
                </div>
            </div>
        )
    }
}