import { Component } from "react";

export default class Dapp extends Component {
    render() {
        return (
            <div class='dapp-container-1'>
                Random
            </div>
        )
    }
}